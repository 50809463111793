<template>
  <div>
    <v-row class="pt-12" align="center" justify="center">
      <v-col cols="6">
        <h4 class="text-center text-h4">Delivery</h4>
      </v-col>
    </v-row>
    <v-row class="pt-0 pb-4" align="center" justify="center">
      <v-col cols="4" class="d-flex justify-center align-center">
        <v-radio-group @change="onChange" v-model="currentItem.type" row>
          <v-radio label="Hard Drive" value="Hard Drive"></v-radio>
          <v-radio label="Digital" value="Digital"></v-radio>
          <v-radio label="Unsure" value="Unsure"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row
      v-if="currentItem.type === 'Hard Drive'"
      class="pt-0"
      align="center"
      justify="center"
    >
      <v-col cols="6" class="d-flex justify-center align-center pt-0">
        <p class="font-weight-bold">Drive Format</p>
      </v-col>
    </v-row>
     <v-row
      v-if="currentItem.type === 'Hard Drive'"
      class="pt-0"
      align="center"
      justify="center"
    >
      <v-col cols="6" class="d-flex justify-center align-center pb-4 pt-0">
        <v-radio-group
          v-model="currentItem.format"
          @change="onChange"
          row
        >
          <v-radio
            label="ExFAT"
            value="ExFAT"
          ></v-radio>
          <v-radio
            label="NTFS"
            value="NTFS"
          ></v-radio>
          <v-radio label="Mac" value="Mac"></v-radio>
          <v-radio
            label="Other"
            value="Other"
          ></v-radio>
        </v-radio-group>
      </v-col>
     </v-row>
      <v-row
      v-if="currentItem.type === 'Hard Drive'"
      class="pt-0"
      align="center"
      justify="center"
    >
      <v-col cols="4" class="d-flex justify-center align-center pb-4 pt-0">
        <v-text-field
          v-if="currentItem.format === 'Other'"
          v-model="currentItem.formatOther"
          @change="onChange"
          label="Format"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row
      v-if="currentItem.type === 'Digital'"
      class="pt-0"
      align="center"
      justify="center"
    >
      <v-col cols="4" class="pt-0 d-flex justify-center align-center">
        <v-radio-group
          @change="onChange"
          v-model="currentItem.digitalMethod"
          row
        >
          <v-radio label="globalDATA" value="globalDATA"></v-radio>
          <v-radio label="Other" value="Other"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentItem.type === 'Digital' &&
        currentItem.digitalMethod === 'globalDATA'
      "
      class="pt-0 pb-4"
      align="center"
      justify="center"
    >
      <v-col cols="4" class="d-flex">
        <v-text-field
          v-model="currentItem.digitalMethodDetails"
          @change="onChange"
          label="Account Info"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentItem.type === 'Digital' && currentItem.digitalMethod === 'Other'
      "
      class="pt-0 pb-4"
      align="center"
      justify="center"
    >
      <v-col cols="4" class="d-flex">
        <v-text-field
          v-model="currentItem.digitalMethodDetails"
          @change="onChange"
          label="Other Digital Delivery Method"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row
      v-if="currentItem.type === 'Hard Drive'"
      class="pt-0"
      align="center"
      justify="center"
    >
      <v-col cols="4" class="pt-2 d-flex justify-center align-center">
        <v-radio-group
          label="Back-up needed?"
          @change="onChange"
          v-model="currentItem.backup"
          row
        >
          <v-radio label="Yes" value="Yes"></v-radio>
          <v-radio label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row
      v-if="currentItem.type === 'Hard Drive' && currentItem.backup === 'Yes'"
      class="pt-0 pb-4"
      align="center"
      justify="center"
    >
      <v-col cols="4" class="d-flex">
        <v-text-field
          v-model="currentItem.backupCount"
          @change="onChange"
          label="How Many"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pt-1" align="center" justify="center">
      <v-col cols="4" class="pb-1 d-flex justify-center align-center">
        <p>Additional Delivery Instructions</p>
      </v-col>
    </v-row>
    <v-row class="pt-0" align="center" justify="center">
      <v-col cols="4" class="pt-0 d-flex">
        <v-textarea
          outlined
          @change="onChange"
          v-model="currentItem.additionalNotes"
          placeholder="None"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Delivery',
  props: {
    order: Object,
    items: Array
  },
  data: function () {
    return {
      currentSection: 'start',
      project: {
        delivery: {}
      },
      currentItem: {
        type: this.order.delivery.type,
        format: this.order.delivery.format,
        backup: this.order.delivery.backup,
        backupCount: this.order.delivery.backupCount,
        digitalMethod: this.order.delivery.digitalMethod,
        digitalMethodDetails: this.order.delivery.digitalMethodDetails,
        additionalNotes: this.order.delivery.additionalNotes
      }
    }
  },
  methods: {
    onSave () {
      this.project.delivery = { ...this.currentItem }

      this.$emit('onChange', {
        project: this.project
      })
      this.currentSection = 'add'
      this.$emit('onChangeSection', {
        section: 'finish'
      })
    },
    onChange () {
      this.project.delivery = { ...this.currentItem }
      this.$emit('onChange', {
        project: this.project
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
