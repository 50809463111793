<template>
  <v-container fluid class="pb-8" id="page">
    <Delivery :order="getOrder" :items="driveFormats" @onChange="onChange($event)" v-if="getOrder.orderType"/>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex justify-start">
        <v-row>
          <v-col cols="6" class="d-flex justify-start">
            <v-btn right plain outlined @click="startOver"> Start Over </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn class="mr-4" left plain outlined @click="nextStep(4)">
              Back
            </v-btn>
            <v-btn right plain outlined @click="nextStep(6)"> Next </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Delivery from '@/components/Delivery'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'delivery',
  title: 'Delivery',
  components: {
    Delivery
  },
  props: {},
  data: function () {
    return {
      currentSection: 'start',
      order: null,
      driveFormats: [
        { text: 'ExFAT', value: 'ExFAT' },
        { text: 'NTFS', value: 'NTFS' },
        { text: 'Mac', value: 'Mac' },
        { text: 'Other', value: 'Other' }
      ]
    }
  },
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({
      getOrder: 'order/getOrder'
    })
  },
  async created () {
    this.checkParams()
  },
  methods: {
    startOver () {
      this.$emit('startOver')
    },
    onChange (event) {
      this.$emit('onChangeProjectAbout', {
        order: event.project
      })
    },
    showSummary (event) {
      this.currentSection = event.section
    },
    nextStep (n) {
      this.$emit('nextStep', {
        n: n
      })
    },
    async checkParams () {
      this.order = this.getOrder
    }
  }
}
</script>
